import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { CameraProvider } from '../providers/CameraProvider';
import { FileProvider } from '../providers/FileProvider';
import Welcome from '../pages/Welcome/Welcome';
import Checklist from '../pages/Checklist/Checklist';
import IdentificationDocument from '../pages/IdentificationDocument/IdentificationDocument';
import PhoneValidation from '../pages/PhoneValidation/PhoneValidation';
import SelfieValidation from '../pages/SelfieValidation/SelfieValidation';
import Permissions from '../pages/Permissions/Permissions';
import Notice from '../pages/Notice/Notice';
import EngineShipping from '../pages/EngineShipping/EngineShipping';
import LicensePlate from '../pages/LicensePlate/LicensePlate';
import VehicleDocument from '../pages/VehicleDocument/VehicleDocument';
import ContractReview from '../pages/ContractReview/ContractReview';
import Success from '../pages/Success/Success';
import Splash from '../pages/Splash/Splash';
import IdentificationRGWelcome from '../pages/IdentificationRGWelcome/IdentificationRGWelcome';
import IdentificationRgFront from '../pages/IdentificationRgFront/IdentificationRgFront';
import IdentificationRgVerse from '../pages/IdentificationRgVerse/IdentificationRgVerse';
import { StyleProvider } from "../providers/StyleProvider";
import { AuthenticationGate } from './AuthenticationGate';
import UpdateBankInfo from '../pages/UpdateBankInfo/UpdateBankInfo';
import ContractRead from '../pages/ContractRead/ContractRead';

const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, sans-serif',
        subtitle1: {
            fontSize: 16,
            lineHeight: '22px',
        },
        body1: {
            fontWeight: 500,
        },
        button: {
            fontStyle: 'italic',
        },
    },
});

const RootRoute = () => {
    return (
        <ThemeProvider theme={theme}>
            <CameraProvider>
                <FileProvider>
                    <StyleProvider>
                        <Routes>
                            <Route path="/" element={<Welcome />} />
                            <Route path="/f/:guid" element={<Splash />} />
                            <Route element={<AuthenticationGate />}>

                                <Route path="/permissions" element={<Permissions />} />
                                <Route path="/notice/:type" element={<Notice />} />
                                <Route path="/success" element={<Success />} />

                                {/*
                                Páginas que precisam da camera e localização ativa.
                                Sem elas não é permitido acessar.
                                Criar um PermissionsGate para validar
                            */}

                                <Route path="/phone-validation" element={<PhoneValidation />} />
                                <Route path="/checklist" element={<Checklist />} />
                                <Route path="/identification-document/:item" element={<IdentificationDocument />} />
                                <Route path="/identification-document/:item/:tipo/:etapa" element={<IdentificationDocument />} />
                                <Route path="/identification-cnh/:item/:etapa" element={<IdentificationDocument />} />
                                <Route path="/identification-rg-welcome/:item/:etapa" element={<IdentificationRGWelcome />} />
                                <Route path="/identification-rg-front/:item/:etapa" element={<IdentificationRgFront />} />
                                <Route path="/identification-rg-verse/:item/:etapa" element={<IdentificationRgVerse />} />
                                <Route path="/selfie-validation/:item" element={<SelfieValidation />} />
                                <Route path="/engine-shipping/:item" element={<EngineShipping />} />
                                <Route path="/license-plate/:item" element={<LicensePlate />} />
                                <Route path="/vehicle-document/:item" element={<VehicleDocument />} />
                                <Route path="/video-confirm/:item" element={<ContractRead />} />
                                <Route path="/contract-review" element={<ContractReview />} />
                                <Route path="/update-bank" element={<UpdateBankInfo />} />
                                <Route path="/*" element={<h1>Not found!</h1>} />
                            </Route>
                        </Routes>
                    </StyleProvider>
                </FileProvider>
            </CameraProvider>
        </ThemeProvider>
    );
}

export default RootRoute;